.menu-link {
  color: rgba(255, 255, 255, 0.8);

  &:hover {
    color: #12a182;
  }

  &.disabled-link {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.35);
  }

  .icon {
    filter: grayscale(1);
  }

  .polygon {
    display: none;
  }

  &-active {
    color: #50e3c2;

    .icon {
      filter: grayscale(0);
    }

    .polygon {
      display: block;
    }
  }
}